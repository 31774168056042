import React, { FC } from 'react';
import './StatfloCaseStudy.scss';
import SectionTitle from '../../components/common/SectionTitle';
import TextDescription from '../../components/common/TextDescription';
import Quote from '../../components/pagesComponents/CaseStudy/Quote';
import YuriiDrozd from '../../assets/img/aboutUs/staff/PM/YuriiDrozd.jpeg';
import DmytroZakharchenko from '../../assets/img/aboutUs/staff/Developers/DmytroZakharchenko.jpeg';
import AntonShloma from '../../assets/img/aboutUs/staff/Developers/AntonShloma.jpeg';
import MaksymGrytsuk from '../../assets/img/aboutUs/staff/Developers/MaksymGrytsuk.jpeg';
import YevgeniyRyzhov from '../../assets/img/aboutUs/staff/Developers/YevgeniyRyzhov.jpeg';
import DmytroDeleur from '../../assets/img/aboutUs/staff/QA/DmytroDeleur.jpeg';
import Links from '../../components/pagesComponents/CaseStudy/Links';
import StatfloLayout from '../../components/PagesLayouts/StatfloLayout';
import PeolpeWhoMade from '../../components/pagesComponents/CaseStudy/PeolpeWhoMade';
import { TOurTeam, TText } from '../../types/types';
import FixedButton from '../../components/common/FixedButton';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const StatfloCaseStudy: FC = () => {
    const ourTalanted: TOurTeam = [
        { id: 0, name: 'Yurii D.', position: 'CEO', img: YuriiDrozd },
        {
            id: 1,
            name: 'Dmytro Z.',
            position: 'Senior Developer',
            img: DmytroZakharchenko,
        },
        { id: 2, name: 'Anton S.', position: 'Senior Developer', img: AntonShloma },
        {
            id: 3,
            name: 'Max G.',
            position: 'Senior Developer',
            img: MaksymGrytsuk,
        },
        {
            id: 4,
            name: 'Zhenya R.',
            position: 'Senior Developer',
            img: YevgeniyRyzhov,
        },
        {
            id: 5,
            name: 'Dmitry D.',
            position: 'Senior QA',
            img: DmytroDeleur,
        },
    ];

    const outcomesText: TText = [
        {
            id: 0,
            text:
                ' The job was successfully done. The shiny new 1:1 Retail Conversations™ became a standalone SaaS product with the unseen easiness of customization to different industries specifics and white-labeling capabilities. It can now provide its users with such features as profanity check, improved better-than-industry-leading compliance, blazing-fast Auto DNC handling, and more.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                '  If 1:1 Retail Conversations™ is not live at the moment you read this, wait for it. If it is already publicly available, definitely give it a good try.',
            margin: 'mb',
        },
    ];

    const beginingStart: TText = [
        {
            id: 0,
            text:
                'Everything started at first as a small remote engagement for a few Blackbird team members and turned into fulfilling partnerships and perfect merge of the on-site team and off-site one.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'Yurii Drozd, Blackbird CEO, System Architect, also traveled to Toronto, Ontario to meet Statflo team, and to discuss posts the important project details alongside learning more about company business and overall product growing vision.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Since the project was already ongoing, and that initial version of 1:1 Retail Conversations™ had been in the use of Statflo customers for a while. Lots of challenges and restrictions of microservice-based application architecture had been already clear. Nevertheless, some other things popped-up later.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                "The big thumb up for the titanic effort of ex-Statflo CTO who designed that architecture and mostly on his own developed the MVP that eventually became the first version of the product. However, he noticed that overall application performance wasn't in the sweet spot back then.",
            margin: 'mb',
        },
    ];

    const beginingEnd: TText = [
        {
            id: 0,
            text:
                'In addition to that, the new, extended Statflo product team observed that some of the features have to be improved from both UX and optimization sides. Things like micro-delays while requests were traveling between one microservice to another sometimes killed the momentum disguised lightness and overall beauty of having a real-time 1:1 conversation with a business you are chatting with. The ability to quickly and easily react and reach a business via one-to-one texting is the core of 1:1 Retail Conversations™ product.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'All of the above plus some predictable product updates and scaling could bring even more complexity to the highly-demanded retail SaaS product, and inconveniences to Statflo customers. They love and value their customers so much, so they even generated an extra $28M of profit for them in 2019. Statflo aims to even larger figures, and the decision had been made, and the work on entirely rethought 1:1 SMS outreach SaaS product was started.',
            margin: 'mb',
        },
    ];

    const rebuildStart: TText = [
        {
            id: 0,
            text:
                'The product team split was a smart and necessary move. Utilizing Squads and Tribes models from Agile management approach we organized our work they way existing product maintains its high reliability for Statflo customers, and the works on the new one wouldn’t interrupt that.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'Former VP of Engineering, on-site designer, and off-site extension team members from Blackbird took care of it. Lots of good things were tried and learned at the beginning of the product rebuilding.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'During the first months of working on the new 1:1 Retail Conversations™ we were operating in quick iterative “Research & Development” mode trying out various approaches. Some of the build within this stage solutions like customer and partner registration based on event sourcing architecture remained in the final version of the product without any further changes.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'Of course, we said “goodbye” to the pure microservice architecture and reasonably selected the CQRS architecture pattern with event sourcing for the vast of product features. However, we haven’t applied it absolutely for every existing and new feature out there since it can be an overkill in such a case.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                'RabbitMQ did a good job previously for building communication between microservices deployed on Kubernetes (K8s). However, it was bringing some delay while posts this communication between microservices was happening.',
            margin: 'mb',
        },
    ];

    const rebuildEnd: TText = [
        {
            id: 0,
            text:
                'Generally, it could be optimized and this delay would be almost unnoticeable. However, not only that was holding the team from keeping and optimizing existing product architecture but the upcoming hot features requested by large retail chains and Tier 1 national carriers triggered that shift.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'The new SaaS product architecture promised not only easiness of adding them but removed some serious limitations of the first version of 1:1 Retail Conversations™. Among those limitations were such things like 1000 conversations per 1 account, and storing posts conversation threads on Statflo side (now it’s stored and pulled from Twilio via API).',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'COVID-19 pandemic changed how teams work around not just in our case but globally. We posts ended up working remotely. This particular case even more diminished the distance between posts members of both teams. Off-site team members are now felt like on-site employees.',
            margin: 'mb',
        },
    ];
    useScrollToTop();

    return (
        <StatfloLayout>
            <div className="statflo-case-study__text">
                <SectionTitle type="primary" title="The beginning " />
                {beginingStart.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Quote>
                    Things like micro-delays while requests were traveling between one microservice
                    to another sometimes disguised lightness and overall beauty of having a
                    real-time 1:1 conversation with a business you are chatting with.
                </Quote>
                {beginingEnd.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="The grand rebuild" />
                {rebuildStart.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}

                <Quote>
                    Of course, we said “goodbye” to the pure microservice architecture and
                    reasonably selected the CQRS architecture pattern with event sourcing for the
                    vast of product features.
                </Quote>

                {rebuildEnd.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="The outcomes" />
                {outcomesText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}

                <Links />
            </div>
            <PeolpeWhoMade page="/statflo-case-study/full-credits/" ourTalanted={ourTalanted} />
            <FixedButton />
        </StatfloLayout>
    );
};

export default StatfloCaseStudy;
